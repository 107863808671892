import adminLayout from '@/layouts/Admin'
import httpAxios from '@/utils/http-axios.js'

export default {
    name: 'AlphabetCreate',
    data() {
        return {
            alphabet: {
                letter: null,
                author: null
            },
            fileAudio: null,
            fileSecondAudio: null,
            fileAnimationJson: null
        }
    },
    components: {
        adminLayout
    },
    methods: {
        createAlphabet() {
            const self = this
            let formData = new FormData()

            if (self.alphabet.letter) formData.append('letter', self.alphabet.letter)
            if (self.fileAudio) formData.append('audio', self.fileAudio)
            if (self.fileSecondAudio) formData.append('second_audio', self.fileSecondAudio)
            if (self.fileAnimationJson) formData.append('animation_json', self.fileAnimationJson)

            httpAxios({
                url: self.$backendUrl + '/api/v1/alphabet',
                method: 'POST',
                data: formData
            }).then(function () {
                self.$router.push({ name: 'admin.alphabet' })

                self.notifySuccess()
            })
        },
        handleFileAudio(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                return this.fileAudio = null
            }

            this.fileAudio = files[0]
        },
        handleFileSecondAudio(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                return this.fileSecondAudio = null
            }

            this.fileSecondAudio = files[0]
        },
        handleFileAnimationJson(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                return this.fileAnimationJson = null
            }

            this.fileAnimationJson = files[0]
        }
    }
}